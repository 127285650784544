// noinspection JSVoidFunctionReturnValueUsed

import React, { useEffect } from 'react'

import Layout from '../../components/common/layout'
import SEO from '../../components/common/seo'
import scrollTo from 'scroll-to-element'

const impressionPixels = {
  segment:
    'https://segment.prod.bidr.io/associate-segment?buzz_key=carbonreach&segment_key=carbonreach-922&value=',
  conversion:
    'https://cnv.event.prod.bidr.io/log/cnv?tag_id=3&buzz_key=carbonreach&value=&segment_key=carbonreach-922&account_id=2&ord=',
}

/**
 * Generate impression pixels in the form of 0 sized image elements.
 * @type {function(): *[]}
 */
const genImpressionPixel = function (pixel) {
  let cachebuster = Math.floor(Math.random() * 10000000000)
  let img = document.createElement('img')
  img.width = 0
  img.height = 0
  if (pixel.includes('ord')) {
    pixel = pixel + cachebuster
  }
  img.src = pixel
  return img
}

/* eslint-disable-next-line */
const runEvent = (function () {
  function gup(name) {
    if (typeof window !== 'undefined') {
      /* eslint-disable-next-line */
      name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
      let regexS = '[\\?&]' + name + '=([^&#]*)'
      let regex = new RegExp(regexS)
      let results = regex.exec(window.location.href)
      if (results == null) return ''
      else return results[1]
    }
  }

  function addEvent(obj, ev, fn) {
    if (obj.addEventListener) {
      obj.addEventListener(ev, fn, false)
      return true
    } else if (obj.attachEvent) {
      return obj.attachEvent('on' + ev, fn)
    }
  }

  if (gup('p') !== '') {
    if (typeof window !== 'undefined') {
      addEvent(window, 'load', function () {
        const iframe = document.getElementById('jv_careersite_iframe_id')
        setTimeout(() => {
          let ifp = sessionStorage['iframePage']
          let container = document.getElementById('openings')
          if (container != null && ifp != null) {
            if (ifp === 'apply') {
              // Drop landing Pixel for adding user to segment
              container.append(genImpressionPixel(impressionPixels.segment))
            } else if (ifp === 'applyConfirmation') {
              // Add Conversion Pixel to application confirmation page
              container.append(genImpressionPixel(impressionPixels.conversion))
            }
          }

          if (iframe) {
            scrollTo('#jv_careersite_iframe_id', { behavior: 'smooth' })
          } else {
            scrollTo('#jobvite-container', {
              behavior: 'smooth',
              align: 'end',
              offset: 800,
            })
          }
        }, 400)
      })
    }
  }
})()

export default function CareersOpenings() {
  useEffect(() => {
    window.onload = (function (win) {
      let el, src, baseUrl, optionMap, path

      optionMap = {
        'data-category': 'c',
        'data-department': 'd',
        'data-location': 'l',
        'data-region': 'r',
        'data-subsidiary': 's',
        'data-type': 't',
      }

      el = document.querySelector('.jv-careersite')
      if (!el) {
        return
      }
      let iframeParameter = el.getAttribute('jobvite-iframeParameter')
      if (iframeParameter === 'true') {
        iframeParameter = 'jobviteiframe'
      } else {
        iframeParameter = 'p'
      }

      if (!el.getAttribute('data-careersite')) {
        console.warn('Jobvite: no careersite detected!')
        return
      }

      win.Jobvite = win.Jobvite || {}
      baseUrl = win.Jobvite.baseUrl || '//jobs.jobvite.com'
      path = getPath(win.location.search, el.getAttribute('data-page'))
      src = [baseUrl, el.getAttribute('data-careersite'), path].join('/')

      // // Drop landing Pixel for adding user to segment
      // let container = document.getElementById('openings')
      // if (container != null) {
      //   container.append(genImpressionPixel(impressionPixels.segment))
      // }

      // Jobvite IFrame not supported on mobile
      if (!detectMobile(win.navigator.userAgent)) {
        renderIframe()
      } else if (shouldRedirect()) {
        win.location.href = src
      } else if (!el.firstChild) {
        renderLink()
      }

      function renderLink() {
        const link = document.createElement('a')
        link.innerHTML = 'View Open Positions'
        link.href = src
        link.classList.add(
          'bg-ci-blue-400',
          'text-white',
          'font-medium',
          'px-8',
          'py-3',
          'mt-8',
          'cursor-pointer',
          'transition',
          'rounded-full',
          'hover:bg-ci-blue-600',
          'duration-300',
          'ease-out'
        )

        el.appendChild(link)
      }

      function renderIframe() {
        let iframe, actions
        iframe = document.createElement('iframe')
        iframe.title = 'Jobvite Career Site'
        iframe.frameBorder = 0
        iframe.style.width = '100%'
        iframe.style.height = '100%'
        iframe.src = src
        iframe.id = 'jv_careersite_iframe_id'
        iframe.className = 'jv-careersite-iframe'
        iframe.name = 'jobvite_careersite_iframe'

        while (el.firstChild) {
          el.removeChild(el.firstChild)
        }

        el.appendChild(iframe)

        actions = {
          action_resize: function (data) {
            iframe.style.height = data.height + 25 + 'px'
          },
          action_navigate: function (data) {
            if (data.href.match(/^(https?:)?\/\//)) {
              win.location = data.href
            } else {
              sessionStorage['iframePage'] = data.href
              win.location.search = updateQueryStringParameter(
                win.location.search,
                iframeParameter,
                encodeURIComponent(data.href)
              )
            }
          },
          action_scrollToFrame: function () {
            iframe.scrollIntoView()
          },
        }

        addEvent(
          win,
          'message',
          function (e) {
            let action // TODO: check if path is coming from page other than jobvite prior to executing
            if (e.source !== iframe.contentWindow) {
              return
            }
            try {
              action = JSON.parse(e.data)
            } catch (err) {
              action = {}
            }
            if (actions[`action_${action.name}`]) {
              actions[`action_${action.name}`](action.data)
            }
          },
          false
        )
      }

      function getPath(search, page) {
        let parts = search ? search.substr(1).split('&') : [],
          defaultPage = '',
          len = parts.length,
          extra = ['nl=1'],
          path = page || defaultPage,
          pathParts,
          param,
          prop,
          propValue,
          i

        for (i = 0; i < len; i++) {
          param = parts[i].split('=')
          if (param[0] === iframeParameter) {
            pathParts = decodeURIComponent(param[1]).split('?')
            path = pathParts[0]
            if (pathParts[1]) {
              extra.push(pathParts[1])
            }
          } else {
            extra.push(parts[i])
          }
        }

        for (prop in optionMap) {
          /* eslint-disable-next-line */
          if (optionMap.hasOwnProperty(prop)) {
            propValue = el.getAttribute(prop)
            if (propValue) {
              extra.push(optionMap[prop] + '=' + propValue)
            }
          }
        }
        const forceRedirect = el.getAttribute('data-force-redirect') !== null
        extra.push(`fr=${forceRedirect}`)

        if (decodeURI(path).match(/\.[\s\n\r]*\./)) {
          path = defaultPage
        }
        return path + (extra.length ? '?' + extra.join('&') : '')
      }

      function shouldRedirect() {
        const forceRedirect = el.getAttribute('data-force-redirect') !== null
        const noRedirect = el.getAttribute('data-no-redirect') !== null
        const page = el.getAttribute('data-page')

        if (forceRedirect) {
          return true
        }
        if (noRedirect && page && path.indexOf(page) === 0) {
          return false
        }
        if (path.charAt(0) !== '?') {
          return true
        }
        return false
      }

      function addEvent(obj, ev, fn) {
        if (obj.addEventListener) {
          obj.addEventListener(ev, fn, false)
          return true
        } else if (obj.attachEvent) {
          return obj.attachEvent('on' + ev, fn)
        }
      }

      function updateQueryStringParameter(queryString, key, value) {
        var regex = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
        var separator = queryString.indexOf('?') !== -1 ? '&' : '?'
        if (queryString.match(regex)) {
          return queryString.replace(regex, `$1${key}=${value}$2`)
        } else {
          return `${queryString}${separator}${key}=${value}`
        }
      }

      function detectMobile(useragent) {
        if (useragent.match(/^(?=.*Android)(?=.*Mobile).*/)) {
          return true
        }
        if (useragent.match(/.*iphone.*/i)) {
          return true
        }
        return false
      }
    })(window)
  }, [])
  return (
    <Layout>
      <SEO
        title="Career Opportunities"
        description="Opportunities change frequently, but we are always looking for talented people to join us. Click here to upload your resume."
      />
      <section
        className="w-full h-full bg-white p-6 pt-14"
        id="openings"
        style={{ marginTop: '96px' }}
      >
        <div className="mx-auto container">
          <h2 className="text-3xl lg:text-4xl font-semibold lg:text-left xl:text-5xl">
            Career Opportunities
          </h2>
          <p className="mt-6 relative z-10 leading-relaxed text-lg 2xl:text-xl max-w-2xl">
            Opportunities change frequently, but we are always looking for
            talented people to join us.{' '}
            <a
              className="text-ci-blue-400 hover:underline"
              href="https://jobs.jobvite.com/clarityinnovations/apply"
              rel="noopener noreferrer"
              target="_blank"
            >
              Click here to upload your resume
            </a>
            .
          </p>
          <div className="mt-8" id="jobvite-container">
            <div
              className="jv-careersite"
              data-careersite="clarityinnovations"
            ></div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
